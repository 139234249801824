import { Global, css } from "@emotion/core"

export default () => (
	<Global
		styles={css`
			*,
			*::after,
			*::before {
				box-sizing: border-box;
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				font-smoothing: antialiased;
				margin: 0;
			}
			html {
				line-height: 1.15;
				-webkit-text-size-adjust: 100%;
				text-size-adjust: 100%;
				font-size: 62.5%;
				background: #f9f8f4;
			}
		`}
	/>
)
