import { TTheme } from "../../types"

const Theme: TTheme = {
	colours: {
		primary: "#000",
		inverted: "#F9F8F4",
		tones: {
			neutral: "#A4A4A4",
		},
		on: {
			image: "#fff",
			primary: "#F9F8F4",
			inverted: "#000",
		},
		surface: `#F9F8F4`,
		shadow: "0 4px 4px rgba(0, 0, 0, .25)",
	},
	font: {
		family: {
			title: `"Playfair Display SC", serif`,
			heading: `"Advent Pro", sans-serif`,
			body: `"Oxygen Mono", sans-serif`,
		},
		size: {
			xxxs: "1.3rem",
			xxs: "1.4rem",
			xs: "1.6rem",
			s: "1.8rem",
			m: "2.0rem",
			l: "2.4rem",
		},
	},
	spacing: {
		xxxs: "0.1rem",
		xxs: "0.2rem",
		xs: "0.4rem",
		s: "0.8rem",
		m: "1.2rem",
		l: "1.6rem",
		xl: "2.4rem",
		xxl: "3.2rem",
		xxxl: "4.6rem",
		xxxxl: "9.2rem",
	},
	zIndex: {
		content: 1,
		nav: 2,
		navCloseButton: 3,
		header: 9,
		modal: 10,
	},
}

export { Theme }
