import { Global } from "@emotion/core"
import React, { HTMLProps, ReactFragment } from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import SEO from "../components/seo"
import ImageFavicon from "../images/favicon.png"

export default function DefaultLayout({ children }: HTMLProps<ReactFragment>) {
	return (
		<>
			{/* on index page, the title should be displayed it */}
			<SEO titleTemplate={`%s`} image={ImageFavicon} />
			<Global
				styles={{
					"html, body": { height: "100%" },
					"#gatsby-focus-wrapper, #___gatsby": { height: `100%` },
					"#gatsby-focus-wrapper": {
						minHeight: `100%`,
						display: `flex`,
						flexDirection: `column`,
						alignItems: `stretch`,
					},
				}}
			/>
			<Header />
			<main css={{ flexGrow: 1 }}>{children}</main>
			<Footer />
		</>
	)
}
