import styled from "../theme/styled"

export interface TypographyProps {
	size?: "xxxs" | "xxs" | "xs" | "s" | "m" | "l"
}

const Typography = styled.p<TypographyProps>(props => ({
	fontFamily: props.theme.font.family.body,
	fontSize: props.theme.font.size[props.size || "s"],
	lineHeight: 1.5,
}))

export { Typography }
