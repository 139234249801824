import React from "react"
import { ThemeProvider } from "emotion-theming"
import { Theme } from "./src/theme"
import Global from "./src/theme/global"

export const wrapRootElement = ({ element }) => (
	<>
		<Global />
		<ThemeProvider theme={Theme}>{element}</ThemeProvider>
	</>
)
