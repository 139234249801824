import { Link } from "gatsby"
import styled from "../theme/styled"
import { Typography } from "./typography"

const BaseLink = styled(Typography)(props => ({
	textDecoration: `none`,
	color: props.theme.colours.primary,
	span: {
		position: "relative",
	},
	"span:hover:after": {
		transform: "scaleX(1)",
		transformOrigin: "left center",
	},
	"span:after": {
		position: "absolute",
		bottom: "0",
		left: "-1%",
		width: "102%",
		height: "1px",
		content: "' '",
		transition: "transform 0.8s cubic-bezier(.1, .95, .15, 1)",
		transform: "scaleX(0)",
		transformOrigin: "right center",
		backgroundColor: props.theme.colours.primary,
		willChange: "transform",
	},
}))

const PlainLink = BaseLink.withComponent("a")
const InternalLink = BaseLink.withComponent(Link)

export { PlainLink, InternalLink }
