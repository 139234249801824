import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Typography } from "./typography"
import { TTheme } from "../../types"
import { PlainLink, InternalLink } from "./link"
import styled from "../theme/styled"
import {
	TiSocialFacebookCircular,
	TiSocialInstagram,
	TiSocialLinkedin,
} from "react-icons/ti"
import mq from "../theme/mq"
import { Link } from "gatsby"

export default function Footer() {
	const {
		allInfo: { nodes: links },
	} = useStaticQuery<any>(graphql`
		query MyQuery {
			allInfo: allSanityInfo {
				nodes {
					facebookUrl
					linkedInUrl
					instagramAcc
				}
			}
		}
	`)
	return (
		<footer
			css={(theme: TTheme) => ({
				background: theme.colours.surface,
				padding: `${theme.spacing.m}`,
			})}
		>
			<div
				css={(theme: TTheme) => ({
					border: `1px solid ${theme.colours.primary}`,
					padding: `${theme.spacing.xl}`,
				})}
			>
				<nav
					css={{
						display: `flex`,
						justifyContent: `center`,
						flexWrap: `wrap`,
					}}
				>
					<NavLink to="/art">
						<span>Art</span>
					</NavLink>
					<NavLink to="/fresh-paint">
						<span>Fresh Paint</span>
					</NavLink>
					<NavLink to="/featured-artist">
						<span>Featured Artist</span>
					</NavLink>
					<NavLink to="/about">
						<span>About</span>
					</NavLink>
					<NavLink to="/contact">
						<span>Contact</span>
					</NavLink>
				</nav>
				<div
					css={(theme: TTheme) => ({
						display: `flex`,
						justifyContent: `center`,
						textAlign: `center`,
						padding: `${theme.spacing.xxl} 0 ${theme.spacing.xl}`,
					})}
				>
					<Typography size="xs">
						Sheree Faria <Dot>•</Dot>{" "}
						<PlainLink
							href={"mailto:sheree@fariafineart.co.uk"}
							size="xs"
						>
							<span>sheree@fariafineart.co.uk</span>
						</PlainLink>{" "}
						<Dot>•</Dot> <One aria-hidden>London, England</One>
						<Two>
							London <br /> England
						</Two>
					</Typography>
				</div>
				<div
					css={(theme: TTheme) => ({
						display: `flex`,
						justifyContent: `center`,
						padding: `${theme.spacing.s}`,
					})}
				>
					<FooterLink href={links[0].facebookUrl}>
						<TiSocialFacebookCircular
							css={{ width: `30px`, height: `30px` }}
						/>
					</FooterLink>
					<FooterLink
						href={`https://instagram.com/${links[0].instagramAcc}`}
					>
						{" "}
						<TiSocialInstagram
							css={{ width: `30px`, height: `30px` }}
						/>
					</FooterLink>
					<FooterLink href={links[0].linkedInUrl}>
						{" "}
						<TiSocialLinkedin
							css={{ width: `30px`, height: `30px` }}
						/>
					</FooterLink>
				</div>
				<div
					css={(theme: TTheme) => ({
						display: `flex`,
						justifyContent: `center`,
						padding: `${theme.spacing.m}`,
					})}
				>
					<Typography size="xxxs">
						website by Satsumastudio
					</Typography>
				</div>
			</div>
		</footer>
	)
}

const One = styled.span({
	display: `none`,
	[mq.small]: {
		display: `inline`,
	},
})

const Two = styled.span({
	display: `inline`,
	[mq.small]: {
		display: `none`,
	},
})

const Dot = styled.span({
	display: `none`,
	[mq.medium]: {
		display: `inline`,
	},
})

const NavLink = styled(InternalLink)(props => ({
	padding: `0 ${props.theme.spacing.l}`,
	textAlign: `center`,
	flex: `1 1 100%`,
	[mq.large]: {
		flex: `0 1 auto`,
	},
	[mq.xlarge]: {
		padding: `0 ${props.theme.spacing.xxl}`,
	},
}))

const FooterLink = styled("a")(props => ({
	textDecoration: `none`,
	color: props.theme.colours.primary,
	span: {
		position: "relative",
	},
	"span:hover:after": {
		transform: "scaleX(1)",
		transformOrigin: "left center",
	},
	"span:after": {
		position: "absolute",
		bottom: "0",
		left: "-1%",
		width: "102%",
		height: "1px",
		content: "' '",
		transition: "transform 0.8s cubic-bezier(.1, .95, .15, 1)",
		transform: "scaleX(0)",
		transformOrigin: "right center",
		backgroundColor: props.theme.colours.primary,
		willChange: "transform",
	},
}))
