import React, { useState, useEffect } from "react"
import { InternalLink } from "./link"
import { TTheme } from "../../types"
import styled from "../theme/styled"
import mq from "../theme/mq"
import { FiX, FiMenu } from "react-icons/fi"
import { Global } from "@emotion/core"
import { Link } from "gatsby"

export default function Header() {
	const [open, setOpen] = useState(false)

	useEffect(() => {
		const check = () => {
			if (window.innerWidth > 768) {
				setOpen(false)
			}
		}
		window.addEventListener("resize", check)
		return () => {
			window.removeEventListener("resize", check)
		}
	}, [])

	const onNavItemClick = () => setOpen(false)

	return (
		<>
			<header
				css={(theme: TTheme) => ({
					position: `fixed`,
					top: 0,
					left: 0,
					width: `100%`,
					zIndex: theme.zIndex.header,
					background: theme.colours.surface,
					padding: `${theme.spacing.m} ${theme.spacing.l} ${theme.spacing.l}`,
					borderBottom: `1px solid ${theme.colours.primary}`,
					[mq.large]: {
						position: `relative`,
						top: `auto`,
						left: `auto`,
						padding: `${theme.spacing.m} ${theme.spacing.l} ${theme.spacing.xl}`,
					},
				})}
			>
				<Link
					to="/"
					css={(theme: TTheme) => ({
						display: `block`,
						color: theme.colours.primary,
						textDecoration: `none`,
						lineHeight: 1,
						textAlign: `center`,
						textShadow: theme.colours.shadow,
						fontFamily: theme.font.family.title,
						fontSize: `2.8rem`,
						[mq.medium]: {
							fontSize: `3.6rem`,
						},
						[mq.large]: {
							marginBottom: theme.spacing.xxl,
							fontSize: `4.8rem`,
						},
					})}
				>
					Faria Fine Art
				</Link>
				<nav
					css={(theme: TTheme) => ({
						position: `fixed`,
						top: 0,
						left: 0,
						width: `100%`,
						height: open ? `100vh` : 0,
						overflow: `hidden`,
						background: theme.colours.surface,
						transition: `height 0.3s ease`,
						zIndex: theme.zIndex.nav,
						[mq.large]: {
							position: `relative`,
							height: `auto`,
							justifyContent: `center`,
							flexWrap: `wrap`,
						},
					})}
				>
					<div
						css={{
							height: `100vh`,
							display: `flex`,
							alignItems: `center`,
							justifyContent: `center`,
							[mq.large]: {
								height: `auto`,
								display: `block`,
							},
						}}
					>
						<div
							css={(theme: TTheme) => ({
								display: `flex`,
								alignItems: `center`,
								justifyContent: `center`,
								flexWrap: `wrap`,
							})}
						>
							<NavItem to="/art" onClick={onNavItemClick}>
								<span>Art</span>
							</NavItem>
							<NavItem to="/fresh-paint" onClick={onNavItemClick}>
								<span>Fresh Paint</span>
							</NavItem>
							<NavItem
								to="/featured-artist"
								onClick={onNavItemClick}
							>
								<span>Featured Artist</span>
							</NavItem>
							<NavItem to="/about" onClick={onNavItemClick}>
								<span>About</span>
							</NavItem>
							<NavItem to="/contact" onClick={onNavItemClick}>
								<span>Contact</span>
							</NavItem>
						</div>
					</div>
				</nav>
				<div
					onClick={() => setOpen(!open)}
					css={(theme: TTheme) => ({
						position: `absolute`,
						top: 0,
						right: 0,
						zIndex: theme.zIndex.navCloseButton,
						height: `100%`,
						display: `flex`,
						alignItems: `center`,
						paddingRight: theme.spacing.l,
						paddingLeft: theme.spacing.xxl,
						cursor: `pointer`,
						[mq.large]: {
							display: `none`,
						},
						svg: {
							width: `24px`,
							height: `24px`,
							color: theme.colours.primary,
						},
					})}
				>
					{open ? <FiX /> : <FiMenu />}
				</div>
			</header>
			<Global
				styles={{
					body: {
						paddingTop: `57px`,
						[mq.medium]: {
							paddingTop: `65px`,
						},
						[mq.large]: {
							paddingTop: 0,
						},
					},
				}}
			/>
		</>
	)
}

const NavItem = styled(InternalLink)(props => ({
	textAlign: `center`,
	padding: `0 ${props.theme.spacing.xl}`,
	flex: `1 0 100%`,
	[mq.large]: {
		flex: `0 1 auto`,
	},
}))
