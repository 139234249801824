import React from "react"
import { Helmet } from "react-helmet-async"
import uniqBy from "lodash.uniqby"
import { useStaticQuery, graphql } from "gatsby"

const iteratee = ({ name, property }: { name?: string; property?: string }) =>
	name || property

export default function SEO({
	description: descriptionFromProps,
	lang = "en",
	meta,
	type,
	image: imageFromProps,
	title,
	creator,
	dir = "ltr",
	url,
	titleTemplate,
}: {
	description?: string
	lang?: string
	meta?: any[]
	type?: string
	image?: string
	title?: string
	creator?: string
	dir?: string
	url?: string
	titleTemplate?: string
}) {
	const data = useStaticQuery<any>(graphql`
		query SeoComponent {
			site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
				id
				_id
				author
				keywords
				title
				description
				shareImage {
					asset {
						fluid(maxWidth: 500) {
							src
						}
					}
				}
			}
		}
	`)

	const description = descriptionFromProps || data.site.description
	const image = imageFromProps || data.site.shareImage?.asset.fluid.src

	return (
		<>
			<Helmet
				titleTemplate={titleTemplate || `%s - ${data.site.title}`}
				htmlAttributes={{
					lang,
					dir,
				}}
				title={title || data.site.title}
				meta={uniqBy(
					[
						// also from https://moz.com/blog/meta-data-templates-123
						{
							name: `description`,
							content: description,
						},
						{
							property: `og:title`,
							content: title,
						},
						{
							property: `og:description`,
							content: description,
						},
						{
							property: `og:type`,
							content: type || `website`,
						},
						{
							property: `og:image`,
							content: image,
						},
						{
							property: `og:image:alt`,
							content: title,
						},
						{
							property: `og:url`,
							content: url,
						},
						{
							name: `twitter:card`,
							content: `summary_large_image`,
						},
						{
							name: `twitter:creator`,
							content: creator,
						},
						{
							name: `twitter:title`,
							content: title,
						},
						{
							name: `twitter:description`,
							content: description,
						},
						{
							name: `twitter:image`,
							content: image,
						},
						{
							name: `twitter:image:alt`,
							content: title,
						},
					]
						.concat(meta || [])
						.filter(({ content }) => !!content)
						// hitting it with the double reverse in order
						// to keep the last item passed in, as uniqBy
						// only keeps the first one it finds
						.reverse(),
					iteratee
				).reverse()}
			/>
		</>
	)
}
