type TBreakpoints = `390` | `576` | `768` | `900` | `1200` | `1480`
type TKeys = `small` | `medium` | `large` | `xlarge` | `xxlarge` | `container`

const breakpoints: { key: TKeys; value: TBreakpoints }[] = [
	{ key: `small`, value: `390` },
	{ key: `medium`, value: `576` },
	{ key: `large`, value: `768` },
	{ key: `xlarge`, value: `900` },
	{ key: `xxlarge`, value: `1200` },
	{ key: `container`, value: `1480` },
]

const mq = breakpoints.reduce<{ [key in TKeys]: string }>((curr, bp) => {
	curr[bp.key] = `@media (min-width: ${bp.value}px)`
	return curr
}, {} as any)

export default mq
